import React, { useState, Fragment, useContext, useEffect } from 'react' // useEffect, useContext
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import Recaptcha from 'react-recaptcha'
// import Gdpr from './gdprform' import { GlobalContext } from
// "../../lib-atomic-context";
/* packages */
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { GlobalContext } from '../../lib-atomic-context'
import MaskedInput from 'react-text-mask'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Spinner2 from '../../app-module-cms-section-page-spinner/default'
//dialogBox
import DialogBox from '../../lib-atomic/dialog/dialogbox'
// import Input from "@material-ui/core/Input";
import { Inputs, Buttons, Invalid } from '../../lib-atomic'
import {
  FormHook,
  LocalStorage,
  SessionStorage,
} from '../../lib-atomic-functions' // LocalStorage
import {
  ModuleWrapperXl,
  ModuleSubTitle,
  ModSubWrapp,
  ModXlWrapp,
  ModLongWrapp,
  ModLongFillWrapp,
  ModTitleText,
  ModContentText,
  ModFormLabelText,
} from '../../lib-atomic-design-section'
import { FetchContextApiV2 } from '../../lib-atomic-functions'
import CONFIG from '../config'

/* CONSTANTS */
const LOGINOBJECTS = CONFIG.application.account.objects
const ENDPOINTS = CONFIG.application.account.api.endpoints
const LOGINOBJECTSCONFIG = {
  url: ENDPOINTS.auth.url,
  method: ENDPOINTS.auth.method,
  headers: ENDPOINTS.auth.headers,
  responseType: 'json',
}
const VALIDATION = {
  url: ENDPOINTS.valid.url,
  method: ENDPOINTS.valid.method,
  headers: ENDPOINTS.valid.headers,
  responseType: 'json',
}
// const LOGINFAILRESPONSE = CONFIG.application.account.responses.fail
const UNVERIFIEDRESPONSE = CONFIG.application.account.responses.unverified

/* CONSTANTS */

// Styles
const useStyles = makeStyles((theme) => ({
  column: {
    flexBasis: '50%',
  },
  column2: {
    flexBasis: '100%',
  },
  img: {
    maxWidth: '70%',
    height: 'auto',
    marginBottom: 20,
  },
  paper: {
    // padding: theme.spacing(3),
    color: 'black',
    marginBottom: '30px',
  },
  inline: {
    display: 'flex',
  },
  text: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}))

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 56,
    paddingLeft: '0px',
    paddingRight: '0px',

    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: '0',
  },
}))(MuiExpansionPanelDetails)

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#008938',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#008938',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        margin: classes.margin,
      }}
      {...props}
    />
  )
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      className="dob-input"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder="DD/MM/YYYY" // showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
const login = (props) => {
  const country = LocalStorage.getLS().country
  const { valid, cmscountry } = useContext(GlobalContext)
  const { mastercountry } = cmscountry.state.data
  // const plasticFlag = mastercountry.filter.plasticVisibility
  const plasticFlag = mastercountry.filter((i) => i.abbr === country)[0]
    .plasticVisibility

  const errorResponses =
    LocalStorage.getLS().translation.json.errorresponse_registration_page
  const errorPopup =
    LocalStorage.getLS().translation.json.errorresponse_formValidation
  const mainCurrentlanguage = LocalStorage.getLS().translation.json
  const currentlanguage = LocalStorage.getLS().translation.json.registrationpage
  console.log(currentlanguage)
  const masterCountries = LocalStorage.getLS().countrylanguage
  const { translation } = LocalStorage.getLS()
  console.log(masterCountries)
  console.log('SessionStorage.getLS()', SessionStorage.getLS())
  if (SessionStorage.getLS() == null) {
    SessionStorage.addLS('Tempvalues', {})
  }
  const tempValue = SessionStorage.getLS()
  console.log(tempValue)

  if (translation) {
    LOGINOBJECTS.countryId.initial = translation.mastercountries.countryId
  }

  if (tempValue.Tempvalues && Object.keys(tempValue.Tempvalues).length > 0) {
    LOGINOBJECTS.firstname.initial = tempValue.Tempvalues.firstname
    LOGINOBJECTS.lastname.initial = tempValue.Tempvalues.lastname
    LOGINOBJECTS.email.initial = tempValue.Tempvalues.email

    LOGINOBJECTS.emailconfirm.initial = tempValue.Tempvalues.emailconfirm
    LOGINOBJECTS.dob.initial = tempValue.Tempvalues.dob
    LOGINOBJECTS.password.initial = tempValue.Tempvalues.password
    LOGINOBJECTS.passwordconfirm.initial = tempValue.Tempvalues.passwordconfirm
    LOGINOBJECTS.promocode.initial = tempValue.Tempvalues.promocode
    LOGINOBJECTS.card.initial = tempValue.Tempvalues.card
    LOGINOBJECTS.accessCode.initial = tempValue.Tempvalues.accessCode
  }

  useEffect(() => {
    if (isSms == true) {
      LOGINOBJECTS.isActive.initial = true
    }
  }, [])

  const display = []
  Object.values(mastercountry).map((value, key) => {
    if (value.forRegistration) {
      const element = {
        name: value.name,
        value: value.countryId,
      }
      const isAdded = display.some((i) => i.name === element.name)
      if (!isAdded) display.push(element)
    }
  })
  console.log('display', display)
  /* state */
  const [response, setresponse] = React.useState()
  const [data, setdata] = React.useState()
  const [open, setOpen] = useState(false)
  const [spin, setSpin] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isNext, seisNext] = useState(1)
  // const [verified,     setVerified] = useState(false)
  const [visiblePW, setVisiblePW] = useState(false)
  const [visiblePWC, setVisiblePWC] = useState(false) // setVisiblePWC
  const [isSms, setisSms] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [vv, setvv] = useState()
  const [isTrue, setTrue] = useState(false)
  const [isDob, setDob] = useState(false)
  //Email,promocode,accesscode validation API//
  const [validData] = FetchContextApiV2(VALIDATION)
  const validationExecute = async ({ body }) => {
    valid.dispatch({
      type: valid.actionTypes.FETCH_VALIDATION,
      valid: await validData(
        {
          body,
        },
        valid
      ),
    })
  }
  const validResetExecute = () => {
    valid.dispatch({ type: valid.actionTypes.RESET })
  }

  const execRedirects = (e) => {
    // const errorResponse = LocalStorage.getLS().translation.json
    //   .errorresponse_resetPassword
    // const errorResponse = LocalStorage.getLS().translation.json.errorresponse
    // const { resetPassword } = errorResponse
    if (!e.status && e.err) {
      // fetch fail
      console.log(valid.state, valid.state.data, valid.state.data.response)
      const { isStatus, data } = valid.state
      const { validObject } = data
      const { response } = validObject
      const { outComeCode } = response.data
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      const message = errorResponses[manipulatedOutComeCode]
      if (isSuccessfullyUpdated) {
        setresponse(errorResponses.success)
        setOpen(false)
        props.parentCallback(0)
        props.values(values)
      } else {
        LOGINOBJECTS.email.initial = ''
        var messageobj = {
          outComeCode: '1',
          title: '',
          outComeMessage: message,
        }
        setresponse(messageobj)

        setOpen(true)
        setSpin(false)
        props.parentCallback(1)
        validResetExecute()
      }
    } else {
      // fetch success
      const { isStatus, data } = valid.state
      const { validObject } = data
      const { outComeCode } = validObject
      const key = outComeCode.toString()
      const manipulatedOutComeCode = key.replace('\r\n', '')
      const isSuccessfullyUpdated =
        data && manipulatedOutComeCode === '0' ? true : false
      if (isSuccessfullyUpdated) {
        validResetExecute()
        props.parentCallback(0)
        props.values(values)
        setTrue(true)
      }
    }
  }
  useEffect(() => {
    if (valid.state.data) {
      console.log('valid.state.data', valid.state)
      const { validObject } = valid.state.data
      setdata(validObject)
      //////////////////////////////////////////////////////
      // try {
      //   const { isStatus, data } = valid.state
      //   const { validObject } = data
      //   if (!validObject) throw { status: isStatus, err: false }
      //   const { outComeCode } = validObject
      //   if (outComeCode !== '0') throw { status: isStatus, err: validObject }
      //   execRedirects()
      // } catch ({ status, err }) {
      //   console.log('catchhhh ', status, err)
      //   execRedirects({ status, err })
      // }
      //////////////////////////////////////////////////////
      if (validObject.response) {
        console.log(validObject)
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG_PRE:25') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses['TRD:SUB_REG_PRE:25'],
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG_PRE:22') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_PRE_22,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG_PRE:23') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_PRE_23,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG_PRE:21') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_PRE_21,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG:32') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_32,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (
          validObject.response.data.outComeCode ===
          'TRD:SUB_REG:29AUTH:GET_MODULE:3'
        ) {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_29AUTH_GET_MODULE_3,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG:15') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_15,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG_PRE:24') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_PRE_24,
          }
          setresponse(message)

          setOpen(true)
          setSpin(false)
          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === 'TRD:SUB_REG:30') {
          LOGINOBJECTS.email.initial = ''
          var message = {
            outComeCode: '1',
            title: '',
            outComeMessage: errorResponses.TRD_SUB_REG_3,
          }
          setresponse(message)
          setOpen(true)
          setSpin(false)

          props.parentCallback(1)
          validResetExecute()
        }
        if (validObject.response.data.outComeCode === '0') {
          setresponse(errorResponses.success)
          setOpen(false)
          props.parentCallback(0)
          props.values(values)
        }
      }
      if (validObject.outComeCode === '0') {
        validResetExecute()
        props.parentCallback(0)
        props.values(values)
        setTrue(true)
      }
    }
  }, [valid.state])

  useEffect(() => {
    if (window.screen.width < 800) {
      setDob(true)
    }
  }, [])

  useEffect(() => {
    if (isSms == false) {
      card.value = ''
      //    onChange({...card})
    }
  }, [isSms])
  // specifying verify callback function

  const handleSubmit = async () => {
    setSpin(true)
    setLoaded(true)
    if (
      values.lastname === '' ||
      values.firstname === '' ||
      values.email === '' ||
      values.password === '' ||
      values.lastname === undefined ||
      values.firstname === undefined ||
      values.email === undefined ||
      values.password === undefined
    ) {
      seisNext(1)
      setOpen(true)

      var message = {
        outComeCode: '0',
        title: '',
        outComeMessage: errorPopup.isNull,
      }
      setresponse(message)

      setSpin(false)
      return
    }

    if (values.email != values.emailconfirm) {
      seisNext(1)
      setSpin(false)
      setOpen(true)

      var message = {
        outComeCode: '0',
        title: '',
        outComeMessage: errorPopup.isEmail,
      }
      setresponse(message)

      return
    }
    if (values.password != values.passwordconfirm) {
      seisNext(1)
      setSpin(false)
      setOpen(true)

      var message = {
        outComeCode: '0',
        title: '',
        outComeMessage: errorPopup.password_match,
      }
      setresponse(message)

      return
    }
    if (values.email == LOGINOBJECTS.email.initial && isTrue != false) {
      setSpin(false)
      seisNext(0)
      props.parentCallback(0)
    } else {
      if (values.isActive == false) {
        values.card = ''
        values.accessCode = ''
      }
      SessionStorage.addLS('Tempvalues', values)

      seisNext(0)
      var body = {
        email: values.email,
        card: values.card,
        accessCode: values.accessCode,
        promoCode: values.promocode,
      }
      await validationExecute({ body })
    }
  }
  const handleIsactive = (v) => {
    console.log('2', v)
    if (v == true) {
      values.isActive = v
      isActive.value = v
    }

    if (v == false) {
      values.isActive = v
      isActive.value = v
      card.value = ''
      card.error = [false, false]
      accessCode.error = [false, false]
      accessCode.value = ''
      // LOGINOBJECTS.card.rules = false

      // values.accessCode = ""
      // LOGINOBJECTS.accessCode.initial = ""

      console.log('card1', card)
      // onChange()
      console.log('card2', LOGINOBJECTS.card)
    }
  }
  const handleChange2 = (event) => {
    setExpanded(!expanded)
    setisSms(!isSms)
  }
  const handleChange = () => {
    setisSms(!isSms)
    handleIsactive(!isSms)
    console.log('1', !isSms)
  }

  const { values, onChange, onPick, onSubmit, error } = FormHook.useFormHook(
    handleSubmit,
    LOGINOBJECTS
  )

  const firstname = {
    ...LOGINOBJECTS.firstname,
    error: error.firstname,
    value: values.firstname,
    onChange,
    translation: mainCurrentlanguage,
  }
  const lastname = {
    ...LOGINOBJECTS.lastname,
    error: error.lastname,
    value: values.lastname,
    onChange,
    translation: mainCurrentlanguage,
  }
  const dob = {
    ...props,
    ...LOGINOBJECTS.dob,
    error: error.dob,
    value: values.dob,
    formatDisplay: 'dd/MM/yyyy',
    onPick,
    translation: mainCurrentlanguage,
  }

  const countryId = {
    ...LOGINOBJECTS.countryId,
    error: error.countryId,
    value: values.countryId,
    options: display,
    onChange,
    translation: mainCurrentlanguage,
  }

  const email = {
    ...LOGINOBJECTS.email,
    error: error.email,
    value: values.email,
    onChange,
    translation: mainCurrentlanguage,
  }
  const emailconfirm = {
    ...LOGINOBJECTS.emailconfirm,
    error: error.emailconfirm,
    value: values.emailconfirm,
    onChange,
    translation: mainCurrentlanguage,
  }

  const password = {
    ...LOGINOBJECTS.password,
    error: error.password,
    value: values.password,
    onChange,
    translation: mainCurrentlanguage,
  }
  const passwordconfirm = {
    ...LOGINOBJECTS.passwordconfirm,
    error: error.passwordconfirm,
    value: values.passwordconfirm,
    onChange,
    translation: mainCurrentlanguage,
  }
  const promocode = {
    ...LOGINOBJECTS.promocode,
    error: error.promocode,
    value: values.promocode,
    onChange,
    translation: mainCurrentlanguage,
  }
  const card = {
    ...LOGINOBJECTS.card,
    error: error.card,
    value: values.card,

    onChange,
    translation: mainCurrentlanguage,
  }

  const isActive = {
    ...LOGINOBJECTS.isActive,
    value: isSms,
    onChange,
    translation: mainCurrentlanguage,
  }
  const accessCode = {
    ...LOGINOBJECTS.accessCode,
    error: error.accessCode,
    value: values.accessCode,
    onChange,
    translation: mainCurrentlanguage,
  }

  const handleClose = (value) => {
    setOpen(false)
    // setresponse(false)
  }

  const classes = useStyles()
  const submitButton = {
    fullWidth: true,
  }
  // const failLogin = { error: [LOGINFAILRESPONSE] }
  return (
    <form onSubmit={onSubmit} noValidate>
      {spin && <Spinner2.Popup selectedValue={false} />}
      {response && (
        <DialogBox selectedValue={response} open={open} onClose={handleClose} />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} spacing={3}>
          <Typography
            variant="h5"
            className={classes.paper}
            style={{
              fontFamily: 'SubwayFootlong',
            }}
          >
            {currentlanguage.page_reg_details}
          </Typography>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_firstname}
                </p>
                <Inputs.Text
                  {...firstname}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_lastname}
                </p>
                <Inputs.Text
                  {...lastname}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_country}
                </p>

                <Inputs.Select
                  {...{
                    ...countryId,
                    options: countryId.options.filter(
                      (item) => ![5, 10, 11, 12, 14].includes(item.value)
                    ),
                  }}
                />
                {/* <Inputs.Text {...password}/> */}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
                marginBottom: '12px',
              }}
            >
              <ModSubWrapp>
                <ModXlWrapp>
                  <ModFormLabelText>
                    {currentlanguage.page_reg_DOB}
                  </ModFormLabelText>
                </ModXlWrapp>
                <ModLongWrapp>
                  <Inputs.Date {...dob} />
                </ModLongWrapp>
                <ModLongFillWrapp>
                  <ModContentText>
                    <small>{currentlanguage.page_reg_DOB_treat}</small>
                  </ModContentText>
                </ModLongFillWrapp>
              </ModSubWrapp>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_promo}
                </p>
                <Inputs.Text {...promocode} />
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} spacing={3}>
          <Typography variant="h5" className={classes.paper}>
            <p
              style={{
                fontFamily: 'SubwayFootlong',
                margin: 0,
              }}
            >
              {currentlanguage.page_reg_title_1}
            </p>
          </Typography>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_email}
                </p>
                <Inputs.Text {...email} />
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_confirm_email}
                </p>
                <Inputs.Text {...emailconfirm} />
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <ModXlWrapp>
                <ModFormLabelText>
                  {currentlanguage.page_reg_password}
                </ModFormLabelText>

                <Inputs.Password
                  style={{
                    backgroundColor: '#d3d3d352',
                  }}
                  {...password}
                  visible={visiblePW}
                />

                <div
                  onClick={() => {
                    setVisiblePW(!visiblePW)
                  }}
                ></div>
              </ModXlWrapp>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
                marginTop: '10px',
              }}
            >
              <ModXlWrapp>
                <ModFormLabelText>
                  {currentlanguage.page_reg_confirm_password}
                </ModFormLabelText>

                <Inputs.Password
                  style={{
                    backgroundColor: '#d3d3d352',
                  }}
                  {...passwordconfirm}
                  visible={visiblePWC}
                />

                <div
                  onClick={() => {
                    setVisiblePWC(!visiblePWC)
                  }}
                ></div>
              </ModXlWrapp>
            </div>
          </div>
          {/* <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                width: '100%',
              }}
            >
              <Typography variant="body2" className={classes.text}>
                <p
                  style={{
                    fontFamily: 'SubwayFootlong',
                    margin: 0,
                  }}
                >
                  {currentlanguage.page_reg_promo}
                </p>
                <Inputs.Text {...promocode} />
              </Typography>
            </div>
          </div> */}
        </Grid>
      </Grid>
      {!plasticFlag ? null : (
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" className={classes.text}>
            <p
              style={{
                fontFamily: 'SubwayFootlong',
                margin: 0,
              }}
            >
              {currentlanguage.page_reg_exist_card}
            </p>
          </Typography>

          <div
            style={{
              display: 'flex',
            }}
          >
            <ExpansionPanel square expanded={expanded} onChange={handleChange2}>
              <ExpansionPanelSummary>
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    <p
                      style={{
                        fontFamily: 'SubwayFootlong',
                        margin: 0,
                      }}
                    >
                      {currentlanguage.page_reg_exist_card_option}
                    </p>
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    width: '100%',
                    textAlign: 'end',
                  }}
                >
                  <FormControlLabel
                    control={
                      <IOSSwitch checked={isSms} onChange={handleChange} />
                    }
                    id="panel1d-header"
                    aria-controls="panel1d-content"
                  />
                </div>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <div className={classes.column2}>
                  <ModXlWrapp>
                    {(country == 'gb' || country === 'ie') && (
                      <img
                        className={classes.img}
                        src="https://thq-stormborn-uat.s3-ap-southeast-2.amazonaws.com/assets/stormborn/cards/uk.png"
                      />
                    )}
                    {country != 'gb' && country !== 'ie' && (
                      <img
                        className={classes.img}
                        src="https://thq-stormborn-uat.s3.ap-southeast-2.amazonaws.com/assets/stormborn/cards/2x-fi+(2).png"
                      />
                    )}
                  </ModXlWrapp>
                </div>
              </ExpansionPanelDetails>
              <ExpansionPanelDetails>
                <div className={classes.column2}>
                  <ModXlWrapp>
                    <ModFormLabelText>
                      {' '}
                      {currentlanguage.page_reg_card}
                    </ModFormLabelText>
                    <Inputs.Text
                      {...card}
                      inputProps={{
                        maxLength: 16,
                        isSms,
                      }}
                    />
                  </ModXlWrapp>
                </div>
              </ExpansionPanelDetails>
              <ExpansionPanelDetails>
                <div className={classes.column}>
                  <ModXlWrapp>
                    <ModFormLabelText>
                      {currentlanguage.page_reg_accesscode}
                    </ModFormLabelText>
                    <Inputs.Text
                      {...accessCode}
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  </ModXlWrapp>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
      )}

      {/* <Gdpr page={isNext}/> */}
      <Typography variant="body2" className={classes.text}>
        <strong>
          <p
            style={{
              fontFamily: 'SubwayFootlong',
              margin: 0,
            }}
          >
            {currentlanguage.page_reg_what_we_do}{' '}
            <span>
              <a
                target="_blank"
                style={{
                  color: '#008938',
                  textDecoration: 'underline',
                }}
                href="privacynotice"
              >
                {currentlanguage.page_reg_what_we_do_link}
              </a>
            </span>
          </p>{' '}
        </strong>
      </Typography>
      <Grid container item xs={12} sm={6} spacing={3}>
        <Grid item xs={12} sm={6}>
          <div>
            <Buttons.Normal {...submitButton}>
              {currentlanguage.page_reg_next}
            </Buttons.Normal>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default login
